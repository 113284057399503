/* eslint-disable no-console */
import React, {useEffect, useState, FC} from "react";
import AdyenCheckout from "@adyen/adyen-web";
import {useLocation, useHistory} from "react-router-dom";
import queryString from "query-string";
import Parser from "html-react-parser";
import PageContainer from "../../components/PageContainer/PageContainer";
import "./PaymentPage.scss";
import {
    disableStoredPayment,
    getReturnUrl,
    sessionPayment,
    submitPayment,
    getAdditionalDetails,
    cancelPayment
} from "../../api/payments";
import ROUTES from "../../constants/routes";
import {Alert, Button} from "acca-design-system";
import staticContent from "./PaymentPage.content";

const PaymentPage: FC = ({}) => {
    const styledPaymentContainer = React.useRef(null);
    const [isDropinLoaded, setIsDropinLoaded] = useState(false);

    const location = useLocation();
    const history = useHistory();
    let paymentType = "n\a";
    const {
        countryCode,
        currencyCode,
        merchantAccount,
        merchantReference,
        shopperReference,
        paymentAmount,
        shopperLocale,
        shopperEmail,
        skinCode,
        merchantSig,
        sessionValidity,
        recurringContract,
        skipSelection,
        redirectResult
    } = queryString.parse(location.search);

    const hasRequiredParams = !!redirectResult || (!!currencyCode && merchantAccount && !!merchantReference && !!shopperReference && !!paymentAmount && !!shopperLocale && !!shopperLocale && !!shopperEmail && !!skinCode && !!merchantSig && !!sessionValidity);
    const isRtaApp = !!process.env.RTA_SKIN_CODE && process.env.RTA_SKIN_CODE === skinCode;
    const recurringContractString = recurringContract !== undefined ? recurringContract : ""

    useEffect(() => {

        async function handleRedirect(state) {
            getAdditionalDetails(state).then(result => {
                getReturnUrl(undefined, result, undefined).then(res => {
                    if (process.env.ENV !== "local") {
                        window.location.href = res.returnUrl;
                    } else {
                        console.log(res.returnUrl);
                        history.push(ROUTES.thankYouLocal);
                    }
                })
            })
        }


        async function getPaymentSession() {
            const sessionInfo = await sessionPayment(countryCode, currencyCode, merchantAccount, merchantReference, shopperReference, paymentAmount, shopperLocale, shopperEmail, merchantSig, sessionValidity, skinCode, recurringContractString, skipSelection);

            let checkoutConfigObj = {
                environment: process.env.ADYEN_ENVIRONMENT,
                clientKey: process.env.CLIENT_KEY,
                session: {
                    id: sessionInfo.id,
                    sessionData: sessionInfo.sessionData
                },
                paymentMethodsConfiguration: {
                    card: {
                        showBrandIcon: false,
                        name: "Card Payment",
                        enableStoreDetails: JSON.parse(process.env.ENABLE_STORE_CARD) && !recurringContractString.includes("RECURRING"),
                        hasHolderName: true,
                        holderNameRequired: true,
                        onBrand: (result) => {
                            if (paymentType === "card") {
                                paymentType = result.brand;
                            }
                        },
                    },
                    // TODO: Confirm if this is needed or not?
                    paypal: {
                        blockPayPalCreditButton: true,
                        blockPayPalPayLaterButton: true,
                    }
                }
            };
            if (JSON.parse(process.env.ENABLE_PAYMENT_HANDLERS) == true) {
                checkoutConfigObj = {
                    ...checkoutConfigObj,
                    ...{
                        onSubmit: (state, component) => {
                            submitPayment(shopperReference, shopperEmail, merchantReference, merchantAccount, paymentAmount, currencyCode, state.data, checkout, sessionInfo.recurringProcessingModel, recurringContractString).then(function (result) {
                                if (result.hasOwnProperty('action')) {
                                    component.handleAction(result.action);
                                } else {
                                    getReturnUrl(merchantReference, result, paymentType).then(res => {
                                        if (process.env.ENV !== "local") {
                                            window.location.href = res.returnUrl;
                                        } else {
                                            console.log(res.returnUrl);
                                            history.push(ROUTES.thankYouLocal);
                                        }
                                    })
                                }
                            })
                        },
                        onAdditionalDetails: (state, component) => {
                            getAdditionalDetails(state).then(result => {
                                getReturnUrl(merchantReference, result, paymentType).then(res => {
                                    if (process.env.ENV !== "local") {
                                        window.location.href = res.returnUrl;
                                    } else {
                                        console.log(res.returnUrl);
                                        history.push(ROUTES.thankYouLocal);
                                    }
                                })
                            });
                        }
                    }
                };
            } else {
                checkoutConfigObj = {
                    ...checkoutConfigObj,
                    ...{
                        onPaymentCompleted: (result) => {
                            getReturnUrl(merchantReference, result, paymentType).then(function (response) {
                                if (process.env.ENV !== "local") {
                                    window.location.href = response.returnUrl;
                                } else {
                                    console.log(response.returnUrl);
                                    history.push(ROUTES.thankYouLocal);
                                }
                            });
                        }
                    }
                };
            }
            const checkout = await AdyenCheckout(checkoutConfigObj);
            checkout
                .create("dropin", {
                        showRemovePaymentMethodButton: JSON.parse(process.env.ENABLE_REMOVE_PAYMENT_METHOD),
                        showStoredPaymentMethods: !recurringContractString.includes("RECURRING"),
                        openFirstStoredPaymentMethod: false,
                        openFirstPaymentMethod: false,
                        onSelect: (component) => {
                            paymentType = component.props.type;
                        },
                        onDisableStoredPaymentMethod: (storedPaymentMethodId, resolve, reject) => {
                            try {
                                disableStoredPayment(merchantReference, shopperReference, storedPaymentMethodId, merchantAccount).then(function (response) {
                                    if (JSON.stringify(response.response) === '"[detail-successfully-disabled]"') {
                                        resolve();
                                    } else {
                                        reject();
                                    }
                                });
                            } catch (e) {
                                reject();
                            }
                        },
                        setStatusAutomatically: process.env.ENV === "local",
                        onReady: () => {
                            setIsDropinLoaded(true)
                        }
                    },
                )
                .mount(styledPaymentContainer.current);
        }

        if (redirectResult) {
            const state = {
                data: {
                    details: {
                        redirectResult: redirectResult
                    }
                }
            };
            handleRedirect(state);
        } else if (hasRequiredParams) {
            getPaymentSession();
        }
    }, []);

    function onCancelPayment() {
        cancelPayment(merchantReference).then(result => {
            window.location.href = result.returnUrl;
        })
    }

    const renderMain = () => {
        return (
            <PageContainer>
                {isDropinLoaded && !isRtaApp && (
                    <Alert className="u-margin-bottom-2" type="info">{Parser(staticContent.defaultAlertMessage)}</Alert>)}
                {isDropinLoaded && isRtaApp && (
                    <div><Alert className="u-margin-bottom-2" type="info">{Parser(staticContent.rtaAlertMessage1)}</Alert>
                        <Alert className="u-margin-bottom-2" type="info">{Parser(staticContent.rtaAlertMessage2)}</Alert>
                        <Alert className="u-margin-bottom-2" type="info">{Parser(staticContent.rtaAlertMessage3)}</Alert>
                    </div>)}
                {isDropinLoaded && !isRtaApp && !recurringContractString.includes("RECURRING") && (
                    <Alert className="u-margin-bottom-2" type="info">{Parser(staticContent.unionPayAlertMessage)}</Alert>)}
                {isDropinLoaded && !recurringContractString.includes("RECURRING") && (
                    <Alert className="u-margin-bottom-2" type="info">{Parser(staticContent.storedPaymentAlertMessage)}</Alert>)}
                <div className="styled-dropin-payment-container">
                    {hasRequiredParams ? (<div ref={styledPaymentContainer} className="payment"/>) : (
                        <div>Missing request parameters.</div>)}
                </div>
                {isDropinLoaded && (<Button
                    onClick={onCancelPayment}
                    text={isRtaApp ? staticContent.cancelCtaRtaText : staticContent.cancelCtaText}
                />)}
            </PageContainer>
        );
    };

    return renderMain();
};

export default PaymentPage;
